import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Tooltip wrap=flex",
        "componentName": "Tooltip",
        "wrap": "flex"
      }}>{`<Tooltip id="demo" label="I will provide you with extra information" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Tooltip" mdxType="Props" />
    <h2 {...{
      "id": "children",
      "style": {
        "position": "relative"
      }
    }}>{`Children`}</h2>
    <p><inlineCode parentName="p">{`default: <Icon />`}</inlineCode></p>
    <p>{`The children you provide to the component, will be the elements that are wrapped
by the tooltip. If no children are provided, the component will fallback to an
information-icon.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Tooltip
  id="children1"
  label="I am wrapping the fallback component"
/>
<Tooltip
  id="children2"
  label="I am wrapping a custom component"
>
  <Paper p={3} elevation={3}>
    <Text>It's all about me</Text>
  </Paper>
</Tooltip>
`}</code></pre>
    <h2 {...{
      "id": "label",
      "style": {
        "position": "relative"
      }
    }}>{`Label`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The label is the text that is displayed inside the tooltip.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Tooltip id="label" label="I can display text" />
`}</code></pre>
    <h2 {...{
      "id": "placement",
      "style": {
        "position": "relative"
      }
    }}>{`Placement`}</h2>
    <p><inlineCode parentName="p">{`default: top`}</inlineCode></p>
    <p>{`You can also change the placement of the label.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` this only sets the default placement. If it's placement would fall
outside of the window (e.g. when scrolled to the bottom), it will adapt
accordingly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Tooltip id="placement1" label="Top" placement="top" />
<Tooltip id="placement2" label="Right" placement="right" />
<Tooltip id="placement3" label="Left" placement="left" />
<Tooltip id="placement4" label="Bottom" placement="bottom" />
`}</code></pre>
    <h2 {...{
      "id": "useportal",
      "style": {
        "position": "relative"
      }
    }}>{`UsePortal`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Renders the tooltip in a portal, primarely used in Dialogs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Tooltip id="usePortal" label="UsePortal" usePortal={true} />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      